define("discourse/plugins/hosted-site/discourse/initializers/anon-init", ["exports", "@ember/runloop", "jquery", "discourse/lib/plugin-api"], function (_exports, _runloop, _jquery, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "apply-anon-init",
    initialize() {
      (0, _pluginApi.withPluginApi)(api => {
        api.modifyClass("component:global-notice", Superclass => class extends Superclass {
          didInsertElement() {
            super.didInsertElement(...arguments);
            (0, _runloop.schedule)("afterRender", () => {
              if ((0, _jquery.default)(".alert .trial-expired-message").length > 0 || (0, _jquery.default)(".alert .discourse-hosting-readonly").length > 0) {
                (0, _jquery.default)(".alert-read-only").hide();
                (0, _jquery.default)(".alert-too-few-topics").hide();
              }
            });
          }
        });
      });
    }
  };
});